export enum Lang {
  Eng = 'en',
  Rus = 'ru',
}

export const langs = [Lang.Eng.valueOf(), Lang.Rus.valueOf()];

export enum Routes {
  Home = '/',
  About = '/about',
  Admin = '/admin',
  Book = '/books/:bookId',
  All = '*',
}

const config = {
  isDevelopment: process.env.NODE_ENV === 'development',
  defaultLang: Lang.Eng,
  defaultRoute: Routes.Home,
  contacts: {
    email: 'vladimirzhirov@yahoo.com',
  },
  routesMap: [
    { path: Routes.About },
    // { path: Routes.Admin },
    { path: Routes.Book },
    { path: Routes.Home },
    { path: Routes.All },
  ],
  footerNavRoutes: [
    Routes.Home,
    Routes.About,
    // Routes.Admin,
  ]
};

export default config;
