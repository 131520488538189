import meImageSrc from 'assets/images/me.jpg';
import config from 'config/constants';
import useLang from 'hooks/useLang';

import './style.scss';

const AboutPage = () => {
  const { locale } = useLang();
  return (
    <div className="about-page">
      <h1>{locale.content.aboutMeHeader}</h1>
      <div className="about-page-content">
        <div className="about-page-image">
          <img src={meImageSrc} alt="about the author" />
        </div>
        {/* <div className="about-page-info">{locale.content.aboutMeIntro}</div> */}
        <div className="about-page-contacts">
          <a href={`mailto:${config.contacts.email}`}>{config.contacts.email}</a>
        </div>
      </div>
    </div>
  );
};
export default AboutPage;
