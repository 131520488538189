import { useRoutes } from 'react-router-dom';

import { Routes } from 'config/constants';
import AboutPage from 'pages/About';
import AdminPage from 'pages/Admin'
import HomePage from 'pages/Home';
import BookPage from 'pages/Book';
import ErrorPage, { Types } from 'pages/Error';

import Layout from 'components/Layout';

const MainRouter = () => {
  return useRoutes([
    { path: Routes.All, element: <ErrorPage type={Types.NotFound} /> },
    {
      path: Routes.About,
      element: (
        <Layout>
          <AboutPage />
        </Layout>
      ),
    },
    {
      path: Routes.Home,
      element: (
        <Layout>
          <HomePage />
        </Layout>
      ),
    },
    {
      path: Routes.Book,
      element: (
        <Layout>
          <BookPage />
        </Layout>
      ),
    },
    // {
    //   path: Routes.Admin,
    //   element: (
    //     <Layout>
    //       <AdminPage />
    //     </Layout>
    //   ),
    // },
  ]);
};

export default MainRouter;
